import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Tenant } from '../../store/reviewer/reviewer.interface';
@Injectable({
  providedIn: 'root',
})
export class StorageService {

  constructor(private cookieService: CookieService) { }

  setCookieValue(key:string, value: any) : void{
    this.cookieService.set(key,value,undefined,'/');
  }

  setLocalStorageItem(key:string,value:any){
    localStorage.setItem(key,value?JSON.stringify(value):'');
  }
  getLocalStorageItem(key:string):any{
     let val = localStorage.getItem(key);
     if(val){
      try {val = JSON.parse(val);}
      catch{
        val=null;
      }
     }
     return val;
  }

  getCookieValueByKey(key:string) {
    return this.cookieService.get(key);
  }

  removeAllCookies(){
    this.cookieService.delete('token','/');
    this.cookieService.delete('tokenExpiry','/');
    this.cookieService.delete('role','/');
    this.cookieService.delete('refreshToken','/');  
    this.cookieService.delete('refreshTokenExpiry','/');  
    this.cookieService.delete('isMobile','/');  
    return this.cookieService.deleteAll('/');
  }

  removeCookieByKey(key:string): void {
    this.cookieService.delete(key,'/');
  }

  setUserToken(tokenObj:any): void {
    this.removeAllCookies();
    this.setCookieValue('token', tokenObj ? tokenObj.accessToken : '');
    this.setCookieValue('tokenExpiry', tokenObj ? tokenObj.accessTokenExpiry : '');
    this.setCookieValue('role', tokenObj ? tokenObj.role : '');
    this.setCookieValue('refreshToken', tokenObj ? tokenObj.refreshToken : '');
    this.setCookieValue('refreshTokenExpiry', tokenObj ? tokenObj.refreshTokenExpiry : '');
    this.setCookieValue('isMobile', tokenObj ? tokenObj.isMobile : false);    
  }

  setTenant(tenant:Tenant){
    localStorage.setItem('selectedTenant', JSON.stringify(tenant));
  }

  getTenant(){
    let val = localStorage.getItem('selectedTenant');
    let t = val ? JSON.parse(val) : '';
    return t;
  }
  
  getToken() {
    return this.getCookieValueByKey('token');
  }

  getRole() {
    return this.getCookieValueByKey('role');
  }

  getSideNavToggle(){
    return JSON.parse(localStorage.getItem('isSideNavOpen') || '');
  }

  setSideNavToggle(toggleValue: Boolean){
    return localStorage.setItem('isSideNavOpen', JSON.stringify(toggleValue));
  }
}
